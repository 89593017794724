import React, { useEffect } from 'react'
import '@src/prismic/fragments/page'
import { EventInterface } from '@prismic/lib/dataResolvers'
import { ArticleFeature, ButtonDep as Button } from '@islandsstofa/react'
import { Fade } from 'react-reveal'
import { useSession } from '@src/context/sessionContext'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export type ArticleArchiveProps = {
  limit?: number
  handpickedArticles: EventInterface[]
  title: string
  id: string
}

const ArticleArchive = ({
  handpickedArticles,
  title,
  id,
  limit
}: ArticleArchiveProps) => {
  const { keys, updateKey, initKey } = useSession()
  const initialLoad = limit ?? 6
  const currentKey = keys.find((k: { id: string }) => k.id === id)

  useEffect(() => {
    if (!currentKey) {
      initKey({ id, param: initialLoad })
    }
  }, [])

  return (
    <div className='article-archive container page-slice-spacer'>
      <h2 className='mt-3 mt-lg-0 mb-3'>{title ?? 'Events'}</h2>
      <div className='article-archive__grid'>
        {handpickedArticles
          .slice(0, currentKey?.param ?? initialLoad)
          .map((a, i) => (
            <Fade duration={500} bottom distance={'5px'} key={i}>
              <Link to={a.url}>
                <ArticleFeature
                  title={a.title}
                  text={a.excerpt}
                  image={a.featuredImage}
                  tag={a.tags.length > 0 ? a.tags[0] : undefined}
                  tagType='white'
                  onRenderImage={() => (
                    <Img
                      className='h-100 w-100 image-rounded'
                      fluid={a.featuredImage.fluid}
                    />
                  )}
                />
              </Link>
            </Fade>
          ))}
      </div>
      {currentKey?.param !== undefined &&
        currentKey.param < handpickedArticles.length && (
          <Button
            onClick={() => updateKey({ id, param: initialLoad })}
            className='w-100'
            label='Load more'
            type='primary-inverted'
          />
        )}
    </div>
  )
}

export default ArticleArchive
