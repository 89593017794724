import { FluidObject } from 'gatsby-image'

type MetaFields = {
  uid: string
  url: string
  tags: string[]
  lang: string
  prismicId: string
}

export type ImageType = {
  url: string
  alt: string
  fluid: FluidObject
}

const metaFieldsResolver = (node: any): MetaFields => ({
  uid: node.uid,
  url: node.url,
  tags: node.tags,
  lang: node.lang,
  prismicId: node.prismicId
})

export interface PageInterface extends MetaFields {
  body: any[]
}

export const pageResolver = (node: any): PageInterface => ({
  ...metaFieldsResolver(node),
  body: node.data.body
})

export interface EventInterface {
  prismicId: string
  uid: string
  url: string
  tags: string[]
  lang: string
  title: string
  from: string
  to: string
  time_from: string
  location: string
  featuredImage: {
    url: string
    alt: string
    fluid: FluidObject
  }
  excerpt: {
    html: string
  }
  body: any[]
}

export const eventResolver = (node: any): EventInterface => ({
  prismicId: node.prismicId,
  uid: node.uid,
  url: node.url,
  tags: node.tags,
  lang: node.lang,
  title: node.data.title.text,
  from: node.data.from,
  to: node.data.to,
  location: node.data.location,
  time_from: node.data.time_from,
  featuredImage: node.data.featured_image,
  excerpt: node.data.excerpt,
  body: node.data.body
})

export interface CompanyInterface {
  name: string
  image: {
    url: string
    alt: string
    fluid: FluidObject
  }
  excerpt: {
    html: string
  }
  about: {
    html: string
  }
  website: {
    url: string
  }
  email: string
  tags: string[]
}

export const companyResolver = (node: any): CompanyInterface => ({
  name: node.data.name.text,
  image: node.data.image,
  excerpt: node.data.excerpt,
  email: node.data.email,
  tags: node.tags,
  about: node.data.about,
  website: node.data.website
})

export interface RepresentativeInterface {
  fullName: {
    text: string
  }
  role: string
  email: string
  phone: string
  portrait: {
    alt: string
    url: string
    fluid: FluidObject
  }
  excerpt?: {
    html: string
  }
  about?: {
    html: string
  }
  website?: { url: string }
}

export const representativeResolver = (node: any): RepresentativeInterface => ({
  email: node.data.email,
  fullName: node.data.fullName ?? node.data.name,
  phone: node.data.phone,
  portrait: node.data.portrait,
  role: node.data.role,
  excerpt: node.data.excerpt,
  about: node.data.about,
  website: node.data.website
})
