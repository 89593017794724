import React, { useContext, createContext, useState } from 'react'

type KeyType = { id: string; param: any }

const SessionContext = createContext<{
  keys: KeyType[]
  updateKey: (k: KeyType) => void
  initKey: (k: KeyType) => void
}>({
  keys: [],
  updateKey() {},
  initKey() {}
})

const SessionProvider: React.FC = ({ children }) => {
  const [keys, setKeys] = useState<KeyType[]>([])

  const initKey = (k: KeyType) => setKeys(prev => [...prev, k])

  const updateKey = (key: KeyType) => {
    setKeys(prev => {
      const existing = prev.find(k => k.id === key.id)
      if (existing) {
        let newParam = key.param + existing.param
        return [
          ...prev.filter(k => k.id !== key.id),
          { id: key.id, param: newParam }
        ]
      } else {
        return [...prev, key]
      }
    })
  }

  return (
    <SessionContext.Provider value={{ keys, updateKey, initKey }}>
      {children}
    </SessionContext.Provider>
  )
}

const useSession = () => useContext(SessionContext)

export { SessionProvider, useSession }
