import React from 'react'
import {
  RichText,
  ThreeImages,
  HeroMedia,
  Feature,
  SEO,
  ImageGrid
} from '@islandsstofa/react'
import { propResolver, SliceComponentType, SliceType } from './prop-resolver'
import System from '@cmp/sections/System'
import CompanyCardGrid from '@cmp/sections/CompanyCardGrid'
import PdfTable from '@cmp/sections/PdfTable'
import ArticleArchive from '@cmp/site/ArticleArchive'

const SliceMapping = ({ slice, idx }: { slice: SliceType; idx: number }) => {
  const Components: {
    [key in SliceComponentType | string]: React.ElementType
  } = {
    rich_text: RichText,
    three_images: ThreeImages,
    hero_media: HeroMedia,
    feature: Feature,
    system: System,
    seo: SEO,
    event_archive: ArticleArchive,
    pdf_table: PdfTable,
    company_card_grid: CompanyCardGrid,
    image_grid: ImageGrid
  }

  const Cmp = Components[slice.slice_type]

  if (!Cmp) return null

  const props = propResolver(slice, idx)

  return <Cmp {...props} />
}

export default SliceMapping
