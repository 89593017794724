import React from 'react'
import cn from 'classnames'
import { Icon } from '@islandsstofa/react'
import { Link } from 'gatsby'

type PDF = {
  name: string
  description: {
    html: string
  }
  link: {
    url: string
  }
  downloadable: boolean
  lastItem: boolean
}

export interface PdfTableProps {
  title: string
  description: {
    html: string
  }
  pdfs: PDF[]
}

const PdfItem = ({ name, description, link, downloadable, lastItem }: PDF) => (
  <Link to={link.url}>
    <div
      className={cn('d-flex pdf-table__item', {
        'pdf-table__item--last': lastItem
      })}
    >
      <div className='d-flex w-100'>
        <Icon className='mt-n1' type='filePdf' />
        <div className='ml-2'>
          <p className='pdf-table__item__name parag--sm'>{name}</p>
          <div
            className='mr-3 parag--sm'
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>
      </div>
      <div className='mt-n1'>
        {downloadable ? (
          <Icon type='cloudDownload' />
        ) : (
          <Icon type='externalLink' />
        )}
      </div>
    </div>
  </Link>
)

const PdfTable = ({ title, description, pdfs }: PdfTableProps) => {
  return (
    <div className='container col-lg-7 offset-lg-3 mb-4 mb-lg-5'>
      <h4>{title}</h4>
      <div dangerouslySetInnerHTML={{ __html: description.html }} />
      <div className='mt-3'>
        {pdfs.map((pdf, i) => (
          <PdfItem {...pdf} key={i} lastItem={i === pdfs.length - 1} />
        ))}
      </div>
    </div>
  )
}

export default PdfTable
